<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded && ($user.role==='ROLE_VNII' || $user.role==='ROLE_ADMIN')">
        <div v-if="apiLoaded" class="wrap">
          <a @click="$router.go(-1)" class="back-link">
            <v-icon class="icon-item icon-chevron-left"></v-icon>
            Назад
          </a>

          <div class="two-columns">
            <div class="list">
            <template>
              <table>
                <thead>
                <tr>
                  <th>Регион</th>
                  <th>Дата создания</th>
                  <th>Дата отправки</th>
                  <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="request in requests" :key="request.id">
                  <td>
                    <router-link :to="'/standard/request/' + request.id">{{ getRegionNameByCode(request.regionCode) }}</router-link>
                  </td>
                  <td>{{ request.createdDateTime | dateFormat }}</td>
                  <td>{{ request.sendDateTime | dateFormat }}</td>
                  <td>{{ requestStatuses[request.status] }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="!requests.length" style="height: 48px; margin-top: 20px">
                Не найдены записи по комплексной модернизации
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-else style="height:1000px"></div>
      </div>
    </transition>
  </div>
</template>

<script>

import {requestStatuses} from "@/modules/ApplicationRepository";
import {getFormsByDomain} from "@/modules/api.forms";

export default {
  name: 'StandardRequestComponent',
  components: {
  },
  data: () => ({
    apiLoaded: false,
    domain: 'standard_request',
    requests: [],
    requestStatuses: requestStatuses
  }),
  methods: {
    async loadData() {
      this.requests = (await getFormsByDomain(this.domain)).payload
    },
    getRegionNameByCode(code) {
      return this.$cznDepartmentsByRegionList.find(r => r.code === code)?.name
    },
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    await this.loadData();
    this.apiLoaded = true;
  }
}
</script>

<style scoped lang="scss">
  .wrap > h1 > div:not(.download-menu) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 8px;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #1C1C1F;
    }
    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
    .v-input--selection-controls .v-radio > .v-label {
      font-size: 15px;
    }
    > div {
      &:first-child {
        margin-right: 77px;
      }
    }
  }

  .list {
    table {
      border-collapse: collapse;
      th {
        border-bottom: 1px solid #E8E8E9;
        &:last-child {
          width: 100px;
          text-align: center;
        }
      }
      td {
        &:last-child {
          width: 100px;
          text-align: center;
        }
      }
    }
  }

  .download-menu {
    top: 0;
    margin-top: 0;
  }

  .v-input--radio-group__input {
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
</style>

<style lang="scss">
@import "../../styles/main.scss";
</style>


